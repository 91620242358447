import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../common';
import { useCancelSimulation } from '../report/selectors';
import { useDeletePlanAPI, usePlansAPI } from './selectors';
export var DeletePlanDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose, plan = _a.plan, resetTableState = _a.resetTableState;
    var t = useTranslation(['myPlans', 'translation']).t;
    var _b = useDeletePlanAPI(plan === null || plan === void 0 ? void 0 : plan.basePlanId), deletePlanData = _b[0], deletePlan = _b[1], clearDeletePlan = _b[2];
    var _c = useCancelSimulation(plan === null || plan === void 0 ? void 0 : plan.id, plan === null || plan === void 0 ? void 0 : plan.basePlanId), cancelSimulation = _c[1];
    var _d = usePlansAPI(), fetchPlans = _d[1];
    useEffect(function () {
        if (open && deletePlanData.data) {
            onClose();
            fetchPlans();
            return clearDeletePlan;
        }
    }, [deletePlanData.data, onClose, fetchPlans, open, clearDeletePlan]);
    var handleDeletePlan = function () {
        cancelSimulation({ isCancelAll: true });
        deletePlan();
        resetTableState();
    };
    return (_jsx(ConfirmDialog, { open: open, onClose: onClose, title: t('deletePlanTitle', { nameOfPlan: plan === null || plan === void 0 ? void 0 : plan.name }), message: t('translation:confirmation.yesDelete'), onConfirm: handleDeletePlan, loading: !!deletePlanData.requesting, errors: [deletePlanData.error] }));
};
