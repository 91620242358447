import { MetricTarget } from '../../enum';
export var PERIOD_REPORT_ROWS = {
    serviceLevelForecast: 'Service Level Forecast',
    asa: 'ASA',
    occupancy: 'Occupancy',
    productionHours: 'Production Hours',
    requiredAgents: 'Required Agents',
    supportStaff: 'Support Staff',
    agentsSalaries: 'Agent Salaries',
    supportStaffSalaries: 'Support Staff Salaries',
    costPerWork: 'Cost per work activity',
    hiringAhead: 'Hiring Ahead of Attrition Costs',
    overtimeCosts: 'Overtime Costs (Agents Only)',
    totalBudgets: 'Budget (Agents/Support Staff)',
    totalSupportStaff: '',
    totalSupportStaffSalaries: '',
};
export var ONE_DECIMAL_VALUE = [
    'serviceLevelForecast',
    'occupancy',
    'requiredAgents',
    'supportStaff',
    'totalSupportStaff',
];
export var ONLY_SINGLE_QUEUE = ['serviceLevelForecast', 'asa', 'occupancy'];
export var STAFF_REQUIREMENT_ROWS = [
    'serviceLevelForecast',
    'asa',
    'occupancy',
    'productionHours',
    'requiredAgents',
    'supportStaff',
    'totalSupportStaff',
];
export var STAFF_BUDGET_ROWS = [
    'agentsSalaries',
    'supportStaffSalaries',
    'hiringAhead',
    'overtimeCosts',
    'totalBudgets',
];
export var COST_ROWS = ['costPerWork'];
export var PERCENTAGES = ['serviceLevelForecast', 'occupancy'];
export var CURRENCY = [
    'agentsSalaries',
    'supportStaffSalaries',
    'hiringAhead',
    'overtimeCosts',
    'totalBudgets',
    'costPerWork',
];
export var HIDE_ROW = [
    'productionHours',
    'requiredAgents',
    'supportStaff',
    'agentsSalaries',
    'supportStaffSalaries',
    'serviceLevelForecast',
    'asa',
    'occupancy',
];
export var TOTAL_DATA = ['totalSupportStaff', 'totalSupportStaffSalaries'];
export var ONLY_PLAN_DATA = ['hiringAhead', 'overtimeCosts', 'totalBudgets'];
export var RESOURCING_CHARTS = ['requiredAgents', 'totalSupportStaff'];
export var FINANCIAL_CHARTS = ['totalBudgets', 'avgCostPerWork'];
export var metricTargetMap = {
    asa: MetricTarget.ASA,
    occupancy: MetricTarget.Occupancy,
    serviceLevelForecast: MetricTarget.ServiceLevel,
};
export var DECIMAL_VALUE_MAP = {
    requiredAgents: 1,
    totalSupportStaff: 1,
    avgCostPerWork: 2,
};
