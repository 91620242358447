import { faEarthAmericas } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Link, Menu, MenuItem, Stack, Typography, styled } from '@mui/material';
import { FluidContainer, OrgType } from 'common-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AUTH_URL, GENESYS_REGIONS, GenesysRegionOption } from './constants';

const BrandLogo = styled(Box)(({ theme }) => ({
  height: theme.typography.pxToRem(20),
})) as typeof Box;

interface SignInButtonProps {
  url: string;
}

const SignInButton = (props: React.PropsWithChildren<SignInButtonProps>) => {
  return (
    <Button size="large" sx={{ height: '3rem' }} variant="outlined" onClick={() => window.location.replace(props.url)}>
      <BrandLogo component="img" src="/assets/images/logo.png" alt="Cinareo" />
      {props.children}
    </Button>
  );
};

const GenesysSignInOption = (props: React.PropsWithChildren) => {
  const { t } = useTranslation();
  const [region, setRegion] = useState<GenesysRegionOption>(GENESYS_REGIONS[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (r: GenesysRegionOption) => {
    setRegion(r);
    handleClose();
  };

  const authUrl = useMemo(() => {
    const { searchParams, origin, pathname } = new URL(AUTH_URL[OrgType.Genesys]);
    searchParams.set('targetEnv', region.targetEnv);
    searchParams.set('hostOrigin', region.hostOrigin);
    return `${origin}${pathname}?${searchParams}`;
  }, [region]);

  return (
    <Stack>
      <SignInButton url={authUrl}>{props.children}</SignInButton>
      <Stack
        component={Typography}
        variant="body2"
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        minWidth="15rem"
      >
        <FontAwesomeIcon icon={faEarthAmericas} />
        <Box component="span">{region.name}</Box>
        <Link component="button" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
          {t('userGuide.change')}
        </Link>
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
          {GENESYS_REGIONS.map(
            (r) =>
              r !== region && (
                <MenuItem key={r.targetEnv} onClick={() => handleChange(r)}>
                  {r.name}
                </MenuItem>
              )
          )}
        </Menu>
      </Stack>
    </Stack>
  );
};

export const HubspotSign = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type');
  if (type) {
    localStorage.setItem('HubspotAuthType', type);
  }
  const authType = localStorage.getItem('HubspotAuthType');
  const authUrl = authType && AUTH_URL[authType as OrgType];
  if (authUrl) {
    window.location.replace(authUrl);
    return null;
  }

  return (
    <FluidContainer pageTitle={t('userGuide.choose')}>
      <Stack>
        <SignInButton url={AUTH_URL[OrgType.Cinareo]} />
        <Typography variant="subtitle2">{t('userGuide.or')}</Typography>
        <GenesysSignInOption>
          <Typography mx={2} variant="h1" fontWeight="normal">
            /
          </Typography>
          <BrandLogo component="img" src="/assets/images/genesys-logo.png" alt="Genesys" marginBottom={1} />
        </GenesysSignInOption>
      </Stack>
    </FluidContainer>
  );
};
