import { MetricTarget } from '../../enum';
export var ANNUAL_REPORT_ROWS = {
    serviceLevelForecast: 'Service Level Forecast',
    asa: 'ASA (sec)',
    occupancy: 'Occupancy',
    productivity: 'Productivity',
    productionHours: 'Total Production Hours',
    requiredAgents: 'Total Required Agents (Paid)',
    effectiveAgents: 'Effective Required Agents',
    different: 'Difference (Learning curve cost)',
    effectiveRate: 'Effective Capacity Rate',
    agentsSalaries: 'Agent Salaries',
    supportStaffSalaries: 'Support Staff Salaries',
    hiringAhead: 'Hiring Ahead of Attrition Costs',
    overtimeCosts: 'Overtime Costs (Agents Only)',
    totalBudgets: 'Total Budget (Agents/Support Staff)',
    defQueue: 'Deferrable',
    nonDefQueue: 'Non-deferrable',
    supportStaff: 'Support Staff',
    costPerWork: 'Cost per Work Type',
    staffSalaries: '',
    threshold: '',
    multiSkill: 'Multi-skill',
};
export var metricTargetMap = {
    asa: MetricTarget.ASA,
    occupancy: MetricTarget.Occupancy,
    serviceLevelForecast: MetricTarget.ServiceLevel,
};
export var SERVICE_ROWS = {
    defQueue: ['productivity'],
    nonDefQueue: ['serviceLevelForecast', 'asa', 'occupancy'],
};
export var AGENT_ROWS = {
    defQueue: ['requiredAgents'],
    nonDefQueue: ['requiredAgents'],
    multiSkill: ['requiredAgents'],
    both: ['requiredAgents', 'effectiveAgents', 'different', 'effectiveRate'],
};
export var PRODUCTION_HOURS_ROWS = {
    defQueue: ['productionHours'],
    nonDefQueue: ['productionHours'],
    multiSkill: ['productionHours'],
    both: ['productionHours'],
};
export var BUDGET_ROWS = {
    both: ['totalBudgets', 'agentsSalaries', 'supportStaffSalaries', 'hiringAhead', 'overtimeCosts'],
};
export var SUPPORT_STAFF_ROWS = {
    both: ['supportStaff'],
};
export var COST_ROWS = {
    both: ['costPerWork'],
};
export var OCCUPANCY_ROWS = {
    defQueue: ['productivity'],
    nonDefQueue: ['occupancy'],
    multiSkill: ['occupancy'],
};
export var INCLUDE_ROLE = ['staffSalaries', 'supportStaff'];
export var PERCENTAGES = [
    'productivity',
    'serviceLevelForecast',
    'occupancy',
    'effectiveRate',
];
export var CURRENCY = [
    'staffSalaries',
    'agentsSalaries',
    'supportStaffSalaries',
    'hiringAhead',
    'overtimeCosts',
    'totalBudgets',
    'costPerWork',
];
export var HIDE_ROW = ['agentsSalaries', 'supportStaffSalaries'];
export var CALCULATE_DIFF = ['requiredAgents', 'supportStaff', 'effectiveAgents'];
export var BOLD_ROW = ['productionHours', 'requiredAgents'];
export var DECIMAL_VALUE = {
    serviceLevelForecast: 2,
    occupancy: 2,
    effectiveRate: 2,
    costPerWork: 2,
    requiredAgents: 1,
    effectiveAgents: 1,
    supportStaff: 1,
    different: 1,
};
export var QUEUE_COLOR = [
    '#267278',
    '#65338D',
    '#4770B3',
    '#D21F75',
    '#3B3689',
    '#50AED3',
    '#48B24f',
    '#E57438',
    '#569DD2',
    '#569D79',
    '#58595B',
    '#E4B031',
    '#84D2F4',
    '#CAD93F',
    '#F5C8AF',
    '#9AC483',
    '#9E9EA2',
];
export var ANNUAL_LAYOUT = {
    title: {
        x: 0.1,
        y: 0.95,
        font: {
            color: '#2a3f5f',
        },
    },
    titlefont: {
        size: 20,
    },
    barmode: 'stack',
    xaxis: {
        type: 'linear',
        tick0: 1,
        dtick: 1,
        tickfont: { size: 16 },
        showline: true,
        linewidth: 6,
        linecolor: '#D7D7D7',
        tickmode: 'array',
        automargin: true,
    },
    yaxis: {
        tickfont: { size: 16 },
        type: 'linear',
        autorange: true,
        categoryorder: 'trace',
        linewidth: 6,
        linecolor: '#D7D7D7',
        zeroline: false,
    },
    autosize: true,
    showlegend: false,
    colorway: QUEUE_COLOR,
};
export var MULTISKILL_COLOR = '#FDFCDD';
