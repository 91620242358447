var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, MenuItem } from '@mui/material';
import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrgType, Role, UserStatus } from '../../enum';
import { DataGrid, FallbackComponent, MenuButton, PAGE_SIZE } from '../common';
import { useUsersAPI } from './selectors';
import { DialogMode } from './types';
var getExpiredUserStatus = function (user) {
    if (user.versionId !== Role.Superadmin && moment.utc().isAfter(moment(user.orgs[0].subscriptionEndDate), 'D')) {
        return UserStatus.Inactive;
    }
    return undefined;
};
export var RowMenu = function (props) {
    var t = useTranslation('manageUser').t;
    var expiredUserStatus = getExpiredUserStatus(props.data);
    return (_jsxs(MenuButton, { children: [_jsx(MenuItem, __assign({ onClick: props.handleOpenDialog.bind(null, DialogMode.ResetPassword, props.data) }, { children: t('resetPassword') })), _jsx(MenuItem, __assign({ onClick: props.handleOpenDialog.bind(null, DialogMode.ChangeStatus, props.data), disabled: !!expiredUserStatus }, { children: t("userAction".concat(expiredUserStatus !== null && expiredUserStatus !== void 0 ? expiredUserStatus : props.data.status)) })), _jsx(MenuItem, __assign({ onClick: props.handleOpenDialog.bind(null, DialogMode.DeleteUser, props.data) }, { children: t('deleteUser') }))] }));
};
var useColumns = function (handleOpenDialog, platform) {
    var t = useTranslation(['manageUser', 'translation']).t;
    var columns = useMemo(function () { return [
        {
            field: 'id',
            headerName: '',
            flex: 0.5,
            sortable: false,
            renderCell: function (params) { return _jsx(RowMenu, { data: params.row, handleOpenDialog: handleOpenDialog }); },
        },
        {
            field: 'companyName',
            headerName: t('company'),
            flex: 3,
            renderCell: function (params) { return (_jsx(Link, __assign({ component: "button", title: params.value, onClick: function () {
                    return handleOpenDialog(platform === OrgType.Cinareo ? DialogMode.Company : DialogMode.BillingConfig, params.row);
                } }, { children: params.value }))); },
        },
        {
            field: 'country',
            headerName: t('country'),
            flex: 2.5,
        },
        {
            field: 'name',
            headerName: t('name'),
            flex: 3,
            renderCell: function (params) {
                return platform === OrgType.Cinareo ? (_jsx(Link, __assign({ component: "button", title: params.value, onClick: function () { return handleOpenDialog(DialogMode.User, params.row); } }, { children: params.value }))) : (params.value);
            },
        },
        {
            field: 'email',
            headerName: t('email'),
            flex: 3,
            sortable: false,
        },
        {
            field: 'subscriptionEndDate',
            headerName: t('endDate'),
            flex: 1.5,
            type: 'date',
            valueFormatter: function (params) {
                return platform === OrgType.Cinareo
                    ? moment.utc(params.value).format(t('dateFormat.short', { ns: 'translation' }))
                    : '';
            },
        },
        {
            field: 'status',
            headerName: t('status'),
            flex: 1.5,
            valueGetter: function (params) {
                var _a;
                return platform === OrgType.Cinareo
                    ? t("userStatus".concat((_a = getExpiredUserStatus(params.row)) !== null && _a !== void 0 ? _a : params.value))
                    : '';
            },
        },
        {
            field: 'lastLoginTime',
            headerName: t('lastLogin'),
            flex: 2,
            type: 'dateTime',
            sortable: false,
            valueFormatter: function (params) {
                return params.value && moment(params.value).format(t('dateFormat.long', { ns: 'translation' }));
            },
        },
    ]; }, [handleOpenDialog, platform, t]);
    return platform === OrgType.Cinareo ? columns : columns.slice(1);
};
export var UserTable = memo(function (_a) {
    var handleOpenDialog = _a.handleOpenDialog, _b = _a.platform, platform = _b === void 0 ? OrgType.Cinareo : _b;
    var _c = useUsersAPI(), usersData = _c[0], fetchUsers = _c[1], clearUsersData = _c[2];
    var columns = useColumns(handleOpenDialog, platform);
    var _d = useState({
        pageSize: PAGE_SIZE,
        page: 0,
    }), paginationModel = _d[0], setPaginationModel = _d[1];
    var _e = useState(), sortModel = _e[0], setSortModel = _e[1];
    useEffect(function () {
        fetchUsers();
        return clearUsersData;
    }, [clearUsersData, fetchUsers]);
    var filteredData = useMemo(function () {
        var _a;
        return (_a = usersData.data) === null || _a === void 0 ? void 0 : _a.map(function (user) {
            var _a, _b, _c;
            var filteredOrgs = user.orgs.filter(function (org) { return org.orgType === platform; });
            return __assign(__assign({}, user), { orgs: filteredOrgs, country: (_b = (_a = filteredOrgs[0]) === null || _a === void 0 ? void 0 : _a.country) === null || _b === void 0 ? void 0 : _b.name, companyName: filteredOrgs.map(function (org) { return org.name; }).join(', '), subscriptionEndDate: (_c = filteredOrgs[0]) === null || _c === void 0 ? void 0 : _c.subscriptionEndDate });
        }).filter(function (user) { return user.orgs.length; });
    }, [usersData.data, platform]);
    return (_jsx(FallbackComponent, __assign({ requesting: usersData.requesting !== false }, { children: _jsx(DataGrid, { columns: columns, rows: filteredData !== null && filteredData !== void 0 ? filteredData : [], paginationModel: paginationModel, onPaginationModelChange: setPaginationModel, sortModel: sortModel, onSortModelChange: setSortModel, autoHeight: true }) })));
});
