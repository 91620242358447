import { MetricTarget } from '../../enum';
export var SUMMARY_DATA_ROWS = {
    forecastedDemand: 'Forecasted Demand',
    weightedAht: 'Weighted AHT',
    targetProductivity: 'Target Productivity',
    forecastedSl: 'Forecasted SL',
    forecastedAnswerSpeed: 'ASA (sec)',
    maxOccupancy: 'Max Occupancy Threshold',
    forecastedOccupancy: 'Occupancy',
    totalWorkloadHours: 'Total Workload Hours',
    productionHours: 'Production Hours',
    productiveFte: 'Productive FTE',
    fteAgents: 'Required (Paid) FTE',
    headcountAgents: 'Required (Paid) Headcount',
};
export var PERCENTAGES = [
    'targetProductivity',
    'forecastedSl',
    'maxOccupancy',
    'forecastedOccupancy',
];
export var ONE_DECIMAL_SUMMARY = {
    fteAgents: 1,
    productiveFte: 1,
};
export var DETAIL_LABELS = ['workVolume', 'staffRequired', 'asa', 'serviceLevel', 'occupancy'];
export var ONE_DECIMAL_DETAIL = ['staffRequired'];
export var ONE_DECIMAL_DETAIL_INDEX = [1, 2];
export var PERCENTAGES_DETAIL = ['serviceLevel', 'occupancy'];
export var PERCENTAGES_DETAIL_INDEX = [5, 6, 7, 8];
export var ADJUSTED_COLOR = '#695649';
export var metricTargetMap = {
    forecastedAnswerSpeed: MetricTarget.ASA,
    forecastedOccupancy: MetricTarget.Occupancy,
    forecastedSl: MetricTarget.ServiceLevel,
};
export var FLAG = ' \u2691';
